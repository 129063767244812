import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectPartesDescargar (Vue, filter, search, sorter, page, idalmacen) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addExact('con_devolucion_pendiente', 0)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('cliente_nombre', search)
        .addILike('cliente_razon_social', search)
        .addILike('sistema_descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.idparteTrabajo.value) {
      apiFilter.addILike(filter.idparteTrabajo.field, filter.idparteTrabajo.value)
    }
    const resp = await Vue.$api.call('parteTrabajo.selectPartesDescargar', {
      idalmacen,
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectPartesDescargarRows (Vue, pks, idalmacen) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idparte_trabajo', pks)
    const resp = await Vue.$api.call(
      'parteTrabajo.selectPartesDescargar',
      { idalmacen, filter: apiFilter }
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectAlmacen (Vue, idalmacen) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idalmacen', idalmacen)
    const resp = await Vue.$api.call('almacen.select', {
      filter: apiFilter
    })
    return resp.data.result.dataset[0]
  },
}
